<template>
  <div>
    <validation-observer
      ref="resettPasswordForm"
      #default="{invalid}"
    >
      <b-form
        class="auth-forgot-password-form mt-2"
        @submit.prevent="submit"
      >
        <b-form-group
          label-for="login-password"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.form.password')"
            vid="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-input-group-prepend
                is-text
              >
                <feather-icon icon="LockIcon" />
              </b-input-group-prepend>

              <b-form-input
                id="login-password"
                v-model="password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                type="password"
                name="login-password"
                :placeholder="$t('auth.form.password')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="login-confirm-password"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('auth.form.confirmPassword')"
            vid="confirmPassword"
            rules="required|confirmed:password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-input-group-prepend
                is-text
              >
                <feather-icon icon="LockIcon" />
              </b-input-group-prepend>

              <b-form-input
                id="login-confirm-password"
                v-model="confirmPassword"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                type="password"
                name="login-confirm-password"
                :placeholder="$t('auth.form.confirmPassword')"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid"
          block
        >
          {{ $t('auth.resetPassword.changePassword') }}
        </b-button>
      </b-form>
    </validation-observer>

    <p class="text-center mt-2">
      <b-link :to="{name:'auth-login'}">
        <feather-icon icon="ChevronLeftIcon" /> {{ $t('auth.forgotPassword.backToLogin') }}
      </b-link>
    </p>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BLink,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email, confirmed } from '@validations'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupPrepend,
    BLink,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      // validation
      required,
      email,
      confirmed,
    }
  },
  methods: {
    submit() {
      this.$refs.resettPasswordForm.validate().then(success => {
        if (success) { this.doSendRequest() }
      })
    },

    doSendRequest() {
      const { hash } = this.$route.params
      this.$http.put(`/forget/${hash}`, {
        password: this.password,
      })
        .then(response => {
          const userData = response.data
          userData.role = 'client'
          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('auth.resetPassword.passwordResetToast.title'),
                  icon: 'LockIcon',
                  variant: 'success',
                  text: this.$t('auth.resetPassword.passwordResetToast.body'),
                },
              })
            })
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('auth.resetPassword.invalidTokenToast.title'),
                icon: 'XIcon',
                variant: 'danger',
                text: this.$t('auth.resetPassword.invalidTokenToast.body'),
              },
            })
            return
          }

          this.$refs.resettPasswordForm.setErrors({
            userEmail: [this.$t('auth.forgotPassword.verifyEmail')],
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
